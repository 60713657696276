// Stimulus controller to apply css properties to an element
// Usage:
// <div data-controller="css-setter" data-background-color="#04b586">

import { Controller } from "@hotwired/stimulus"

const supportedProperties = ["background-color", "color"]

export default class extends Controller {

  connect(){
    supportedProperties.forEach((property) => {
      const valueToApply = this.element.getAttribute(`data-${property}`)
      if (valueToApply) {
        this.element.style[property] = valueToApply
      }
    })
  }
}
