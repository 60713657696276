// Stimulus controller for setting active links in secondary_navbar as you scroll

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleScroll(){
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout)
    this.scrollTimeout = setTimeout(() => {
      // If navbar fixed on top, add classes to show study image and Login
      // Active sectionhttps://github.com/HeHStudy/Eureka/pull/21325
      if (window.pageYOffset >= (this.element.offsetTop - 50)) {
        this.element.querySelector('.secondary-web-banner').classList.add('fade-in')
        this.element.querySelector('.secondary-nav-links').classList.add('move-right')
        this.element.querySelector('.secondary-join-now').classList.add('fade-in')
      } else {
        this.element.querySelector('.secondary-web-banner').classList.remove('fade-in')
        this.element.querySelector('.secondary-web-banner').classList.add('fade-out')
        this.element.querySelector('.secondary-join-now').classList.remove('fade-in')
        this.element.querySelector('.secondary-nav-links').classList.remove('move-right')
      }
      let current = ''
      const sections = document.querySelectorAll('.subpage')
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (window.pageYOffset >= (sectionTop - window.innerHeight/2) ) {
          current = section.id
        }
      })

      this.element.querySelectorAll('.nav-item').forEach((li) => {
        li.classList.remove('active')
        if (li.querySelector('a')?.getAttribute('href') == `#${current}`) {
          li.classList.add('active')
        }
      })
    }, 30)
  }
}
